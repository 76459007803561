<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Adicionar Página</h4>

          <div class="page-title-right">
            <b-button
              variant="info"
              @click.prevent="$router.push({ name: 'paginas' })"
            >
              <i class="bx bx-list-ol font-size-16 align-middle mr-2"></i>
              Listar Páginas
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row text-left">
      <div class="col-md-12 col-lg-4">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 mb-3">
                <label>Imagens</label>
                <label class="w-100 cursor-pointer">
                  <div class="uploadimg w-100 border p-5 text-center">
                    <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                    <h6 class="text-muted">clique para enviar imagens</h6>
                  </div>
                  <input
                    type="file"
                    @change="onFileChange"
                    name="image[]"
                    multiple
                    class="d-none"
                  />
                </label>
              </div>
              <div class="col-12" v-if="imgs.length > 0">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Imagem</th>
                        <th scope="col" class="text-center">Capa</th>
                        <th scope="col" class="text-center">Fundo</th>
                        <th scope="col" class="text-center">Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="text-center align-middle"
                        v-for="(i, index) in imgs"
                        :key="index"
                      >
                        <td class="text-left align-middle">
                          <b-img
                            width="100"
                            :src="i.url"
                            rounded
                            alt="Rounded image"
                          ></b-img>
                        </td>
                        <td class="align-middle">
                          <input
                            @click="altCheck(index)"
                            class="form-check-input position-static"
                            type="radio"
                            value="1"
                            v-model="i.capa"
                          />
                        </td>
                        <td class="align-middle">
                          <input
                            @click="altCheckFundo(index)"
                            class="form-check-input position-static"
                            type="radio"
                            value="1"
                            v-model="i.fundo"
                          />
                        </td>
                        <td class="align-middle">
                          <b-button
                            variant="danger"
                            @click.prevent="removerImagem(index)"
                          >
                            <i
                              class="bx bx-trash font-size-16 align-middle"
                            ></i>
                          </b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12 mb-3">
                <label>Modelo</label>
                <multiselect
                  v-model="form.model"
                  :options="options_modelo"
                  class="helo"
                  placeholder="Modelo da página"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  :allow-empty="false"
                ></multiselect>
              </div>
              <div class="col-12 mb-3">
                <label>Status</label>
                <multiselect
                  :options="options_status"
                  v-model="form.status"
                  class="helo"
                  label="text"
                  placeholder="Status"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  :allow-empty="false"
                ></multiselect>
              </div>
              <div class="col-12">
                <label>Vídeo</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Link do vídeo"
                    for="text"
                    v-model="form.video"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-8">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 mb-base">
                <label>
                  <span class="mr-1 text-danger">*</span>
                  Título
                </label>
                <b-form-group>
                  <b-form-input
                    placeholder="Título da página"
                    for="text"
                    v-model="form.title"
                    :class="{ 'is-invalid': form && $v.form.title.$error }"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12 mb-base">
                <label>Subtítulo</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Subtítulo da página"
                    for="text"
                    v-model="form.sub_title"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12 mb-3">
                <b-form-group>
                  <label>Conteúdo</label>
                  <quill-editor v-model="form.content" ref="quillEditorA"></quill-editor>
                </b-form-group>
              </div>
              <div
                class="col-sm-12 col-md-6 mb-base"
                v-if="form.model === 'Autor'"
              >
                <label>Facebook</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Link do facebook"
                    for="text"
                    v-model="form.facebook"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div
                class="col-sm-12 col-md-6 mb-base"
                v-if="form.model === 'Autor'"
              >
                <label>Twitter</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Link do twitter"
                    for="text"
                    v-model="form.twitter"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div
                class="col-sm-12 col-md-6 mb-base"
                v-if="form.model === 'Autor'"
              >
                <label>Instagram</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Link do instagram"
                    for="text"
                    v-model="form.instagram"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div
                class="col-sm-12 col-md-6 mb-base"
                v-if="form.model === 'Autor'"
              >
                <label>YouTube</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Link do youtube"
                    for="text"
                    v-model="form.youtube"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div
                class="col-sm-12 col-md-6 mb-base"
                v-if="form.model === 'Autor'"
              >
                <label>Autor</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Nome do autor"
                    for="text"
                    v-model="form.author"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div
                class="col-sm-12 col-md-6 mb-base"
                v-if="form.model === 'Autor'"
              >
                <label>Contato</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Contato do autor"
                    for="text"
                    v-model="form.contact"
                  ></b-form-input>
                  <small>Exemplo: endereço de e-mail</small>
                </b-form-group>
              </div>
              <div class="col-12 mt-3 text-right">
                <b-button variant="success" @click.prevent="salvarDados()">
                  <i class="bx bx-save font-size-16 align-middle mr-2"></i>
                  Salvar
                </b-button>
                <b-button
                  variant="danger"
                  class="ml-2"
                  @click.prevent="$router.push({ name: 'paginas' })"
                >
                  <i class="bx bx-x font-size-16 align-middle mr-2"></i>
                  Cancelar
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
export default {
  props: {
    edit: {
      type: Object,
      default: function () {
        return null
      },
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      imgs: [],
      options_status: [
        { value: 0, text: 'Ativa' },
        { value: 1, text: 'Bloqueada' },
      ],
      options_modelo: ['Padrão', 'Autor', 'Contato', 'Blog'],
      form: {
        model: 'Padrão',
        status: { value: 0, text: 'Ativa' },
        title: null,
        video: '',
        facebook: '',
        sub_title: '',
        twitter: '',
        instagram: '',
        contact: '',
        youtube: '',
        content: '',
        author: '',
      },
    }
  },
  validations: {
    form: {
      title: { required },
    },
  },
  mounted() {
    if (this.edit != null) {
      this.form = this.edit
      this.form.status = {
        value: this.edit.status,
        text: this.edit.status == 0 ? 'Ativa' : 'Bloqueada',
      }
      if (this.edit.image != null) {
        this.edit.image.forEach((element) => {
          var g = {
            file: null,
            url: element.image300,
            capa: element.capa,
            fundo: element.fundo,
            id: element.id,
          }
          this.imgs.push(g)
        })
      }
    }
  },
  methods: {
    ...mapActions('page', [
      'ActionPageAll',
      'ActionPageCreate',
      'ActionPageUpdate',
      'ActionPageUpdateImage',
      'ActionPageDeleteImage',
    ]),
    salvarDados() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitPage()
      }
    },
    async submitPage() {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Salvando Dados',
          icon: 'info',
          showConfirmButton: false,
        })

        this.form.status = this.form.status.value
        let data = new FormData()
        if (this.imgs.length > 0) {
          this.imgs.forEach((element) => {
            if (element.id == null) {
              data.append('image[]', element.file)
              data.append('capa[]', element.capa)
              data.append('fundo[]', element.fundo)
            }
          })
        }
        data.append('title', this.form.title)
        data.append('sub_title', this.form.sub_title)
        data.append('status', this.form.status)
        data.append('video', this.form.video)
        data.append('facebook', this.form.facebook)
        data.append('twitter', this.form.twitter)
        data.append('instagram', this.form.instagram)
        data.append('content', this.form.content)
        data.append('contact', this.form.contact)
        data.append('youtube', this.form.youtube)
        data.append('model', this.form.model)
        data.append('author', this.form.author)

        if (this.edit != null) {
          data.append('id', this.form.id)
          var dados = {
            data: data,
            id: this.form.id,
          }
          await this.ActionPageUpdate(dados)
        } else {
          await this.ActionPageCreate(data)
        }
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        }).then((_) => {
          this.$router.push({ name: 'paginas' })
        })
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    onFileChange(e) {
      e.target.files.forEach((element, index) => {
        var g = {
          file: element,
          url: URL.createObjectURL(element),
          capa: 0,
          fundo: 0,
          id: null,
        }
        this.imgs.push(g)
        if (
          (this.edit != null && this.edit.image == null) ||
          this.edit == null
        ) {
          this.imgs[0].capa = 1
          this.imgs[0].fundo = 1
        }
      })
    },
    altCheck(i) {
      var t = []
      this.imgs.forEach((element) => {
        if (element.id != null && element.capa == 1) {
          element.capa = 0
          var fg = {
            id: element.id,
            data: element,
          }
          this.ActionPageUpdateImage(fg)
        }
        element.capa = 0
        t.push(element)
      })
      t[i].capa = 1
      if (t[i].id != null) {
        var fg = {
          id: t[i].id,
          data: t[i],
        }
        this.ActionPageUpdateImage(fg)
      }
      this.imgs = t
    },
    altCheckFundo(i) {
      var t = []
      this.imgs.forEach((element) => {
        if (element.id != null && element.fundo == 1) {
          element.fundo = 0
          var fg = {
            id: element.id,
            data: element,
          }
          this.ActionPageUpdateImage(fg)
        }
        element.fundo = 0
        t.push(element)
      })
      t[i].fundo = 1
      if (t[i].id != null) {
        var fg = {
          id: t[i].id,
          data: t[i],
        }
        this.ActionPageUpdateImage(fg)
      }
      this.imgs = t
    },
    removerImagem(e) {
      var img = this.imgs[e]
      this.imgs.splice(e, 1)
      if (img.id != null) {
        this.ActionPageDeleteImage(img.id).then((_) => {
          if (img.capa == 1 && this.imgs.length > 0) {
              this.imgs[0].capa = 1
              this.imgs[0].fundo = 1
          }
          if (this.imgs[0].id != null) {
              var fg = {
              id: this.imgs[0].id,
              data: this.imgs[0],
            }
            this.ActionPageUpdateImage(fg)
          } 
        })
      } else {
        if (img.capa == 1 && this.imgs.length > 0) {
          this.imgs[0].capa = 1
        }
        if (img.fundo == 1 && this.imgs.length > 0) {
          this.imgs[0].fundo = 1
        }
      }
    },
  },
}
</script>
